import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import * as S from '../styles/workout-video.styles'
import Layout from '../templates/Layout'
import * as session from '../services/session'
import { HOME_ROUTE } from '../constants/routes'
import { SectionTag } from '../components/shared'
import { DESKTOP_WIDTH } from '../constants/measurements'
import { workoutSelector } from '../redux/selectors'
import { RootState } from '../redux/reducers/rootReducers'
import { onFetchWorkoutById as onFetchWorkoutByIdAction } from '../redux/actions'
import SpotifyButton from '../components/shared/SpotifyButton'
import { withErrorHandler } from '../components/errorHandler'
import CircularProgress from '@material-ui/core/CircularProgress'
import { v4 as uuidv4 } from 'uuid'
import {
  createSessionSecondWatched,
  saveBatchToFirebase,
} from '../services/firebaseSecondWatched'
import { DateTime } from 'luxon'
import { api, Methods } from '../services/httpService'

interface WorkoutVideoProps {
  location: { state: { workoutId: number }; search: string }
}

const WorkoutVideo: React.FC<Props> = (props: Props) => {
  session.redirectIfNotLoggedIn(HOME_ROUTE)
  const { search } = props.location
  const workoutId = Number(search.split('?id=').pop() || 0)
  const secondsWatchedRef = useRef<number>(0)
  const workoutIdRef = useRef<number>(workoutId)
  const { workout, onFetchWorkoutById } = props
  const [secondsWatched, setSecondWatched] = useState(0)

  /**
   * defining 'sessionId' outside of the component had a bug where
   * we were not getting a new sessionId when going out to workouts and back in
   * to a new workout
   */
  const [sessionId] = useState<string>(uuidv4())
  const userId = session.getId() as number

  useEffect(() => {
    secondsWatchedRef.current = secondsWatched
  }, [secondsWatched])

  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    // purpose of this is to run the cleanup function on unmount
    const id = workoutIdRef.current
    return () => {
      api(Methods.post, `/workout/${id}/${sessionId}`, {
        secondsWatched: secondsWatchedRef.current,
      })
    }
  }, [])

  useEffect(() => {
    onFetchWorkoutById(Number(workoutId || 0))
    createSessionSecondWatched(workoutId, userId, sessionId)
  }, [workoutId, sessionId, userId, onFetchWorkoutById])

  const isDesktopOrSmaller = useMediaQuery({
    query: `(max-width: ${DESKTOP_WIDTH}px)`,
  })

  const handleStartPlaying = () => {
    // recordVideoView()
  }

  const handleSecondWatched = (data: { playedSeconds: number }) => {
    if (data.playedSeconds === 0) {
      return
    }
    const batch = {
      [Math.floor(data.playedSeconds)]: DateTime.utc().valueOf(),
    }
    saveBatchToFirebase(sessionId, batch)
    setSecondWatched(secondsWatched + 1)
  }

  // TODO on exit hitup endpoint

  return (
    <Layout>
      <SectionTag>
        <div
          style={
            !isDesktopOrSmaller ? { paddingLeft: '5%', paddingRight: '5%' } : {}
          }
        >
          {!workout ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <div>
              <S.PlayerWrapper>
                <S.Player
                  url={
                    process.env.GATSBY_VIDEO_TYPE === 'youtube'
                      ? `https://www.youtube.com/watch?v=${workout.videoUrl}`
                      : workout.videoUrl
                  }
                  controls={true}
                  volume={1}
                  muted={false}
                  playing={true}
                  onPlay={handleStartPlaying}
                  onProgress={handleSecondWatched}
                  width="100%"
                  height="100%"
                  config={{
                    file: {
                      attributes: {
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
              </S.PlayerWrapper>

              <S.Row>
                <S.VideoInfoWrap
                  style={
                    isDesktopOrSmaller
                      ? { paddingLeft: '3%', paddingRight: '3%' }
                      : {}
                  }
                >
                  <S.Primary>{workout.title}</S.Primary>
                  <S.Secondary>with {workout.trainerName}</S.Secondary>
                  {workout.headerOne && (
                    <S.NoteHeader>{workout.headerOne}</S.NoteHeader>
                  )}
                  {workout.description.split('\n').map((i, key) => (
                    <S.Note key={key}>{i}</S.Note>
                  ))}
                  {workout.headerTwo && (
                    <S.NoteHeader>{workout.headerTwo}</S.NoteHeader>
                  )}
                  {workout.descriptionTwo &&
                    workout.descriptionTwo
                      .split('\n')
                      .map((i, key) => <S.Note key={key}>{i}</S.Note>)}
                </S.VideoInfoWrap>
                {workout.spotifyLink && (
                  <SpotifyButton spotifyLink={workout.spotifyLink} />
                )}
              </S.Row>
            </div>
          )}
        </div>
      </SectionTag>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  workout: workoutSelector(state),
})

const mapDispatchToProps = {
  onFetchWorkoutById: onFetchWorkoutByIdAction.request,
}

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WorkoutVideoProps

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(WorkoutVideo))
